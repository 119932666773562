define("discourse/plugins/discourse-custom-wizard/discourse/components/wizard-mapper", ["exports", "discourse/plugins/discourse-custom-wizard/discourse/lib/wizard-mapper", "discourse-common/utils/decorators", "@ember/runloop", "@ember/component", "@ember/array"], function (_exports, _wizardMapper, _decorators, _runloop, _component, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("inputs.@each.type"), _dec2 = (0, _decorators.default)("options.@each.inputType"), (_obj = {
    classNames: "wizard-mapper",
    didReceiveAttrs() {
      if (this.inputs && this.inputs.constructor !== Array) {
        (0, _runloop.later)(() => this.set("inputs", null));
      }
    },
    canAdd(inputs) {
      return !inputs || inputs.constructor !== Array || inputs.every(i => {
        return ["assignment", "association"].indexOf(i.type) === -1;
      });
    },
    inputOptions(options) {
      let result = {
        inputTypes: options.inputTypes || "assignment,conditional",
        inputConnector: options.inputConnector || "or",
        pairConnector: options.pairConnector || null,
        outputConnector: options.outputConnector || null,
        context: options.context || null,
        guestGroup: options.guestGroup || false,
        includeMessageableGroups: options.includeMessageableGroups || false,
        userLimit: options.userLimit || null
      };
      let inputTypes = ["key", "value", "output"];
      inputTypes.forEach(type => {
        result[`${type}Placeholder`] = options[`${type}Placeholder`] || null;
        result[`${type}DefaultSelection`] = options[`${type}DefaultSelection`] || null;
      });
      _wizardMapper.selectionTypes.forEach(type => {
        if (options[`${type}Selection`] !== undefined) {
          result[`${type}Selection`] = options[`${type}Selection`];
        } else {
          result[`${type}Selection`] = type === "text" ? true : false;
        }
      });
      return result;
    },
    onUpdate() {},
    actions: {
      add() {
        if (!this.get("inputs")) {
          this.set("inputs", (0, _array.A)());
        }
        this.get("inputs").pushObject((0, _wizardMapper.newInput)(this.inputOptions, this.inputs.length));
        this.onUpdate(this.property, "input");
      },
      remove(input) {
        const inputs = this.inputs;
        inputs.removeObject(input);
        if (inputs.length) {
          inputs[0].set("connector", null);
        }
        this.onUpdate(this.property, "input");
      },
      inputUpdated(component, type) {
        this.onUpdate(this.property, component, type);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "canAdd", [_dec], Object.getOwnPropertyDescriptor(_obj, "canAdd"), _obj), _applyDecoratedDescriptor(_obj, "inputOptions", [_dec2], Object.getOwnPropertyDescriptor(_obj, "inputOptions"), _obj)), _obj)));
});